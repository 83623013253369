import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import from react-router-dom
import logo from '../components/amedic.png';
import { useTranslation } from 'react-i18next';
import  './navbar.css'; // Ensure the path to your CSS file is correct
import 'country-flag-icons/3x2/flags.css';
import englishFlag from "../images/GB.png" // Example path, adjust accordingly
import bosnianFlag from "../images/ba.svg"
import turkishFlag from "../images/TR.png"
const Navbar = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const languages = [
    { code: 'en', name: 'English', image: englishFlag },
    { code: 'bs', name: 'Bosanski', image: bosnianFlag },
    { code: 'tr', name: 'Türkçe', image: turkishFlag}]
  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the state to open/close the side menu
  };
  const closeMenu = () => {
    setIsOpen(false); // Toggle the state to open/close the side menu
  };
  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
  }; 
  const toggleDropdown = () => {
    console.log(isOpen2)
    setIsOpen2(!isOpen2)
  };

  const handleLanguageChange = (langCode) => {
    changeLanguage(langCode);
    setIsOpen2(false); // Close the dropdown after selection
  };
  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Assuming you have a logo import */}
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="Logo" />
        </Link>
        <div className="hamburger" onClick={toggleMenu}>
          {/* Hamburger icon details */}
          <div></div>
          <div></div>
          <div></div>
        </div>
        <ul className={`nav-menu ${isOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMenu}>
              {t('navbar_home')}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/o-nama" className="nav-links" onClick={closeMenu}>
              {t('navbar_aboutUs')}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/prodajni-programi" className="nav-links" onClick={closeMenu}>
              {t('navbar_salesPrograms')}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/novosti" className="nav-links" onClick={closeMenu}>
              {t('navbar_news')}
            </Link>
          </li>
          <li className='nav-item'>
      <div className="language-selector">
        <button onClick={toggleDropdown} className="dropdown-toggle">
        {t('languages')}
        </button>
        {isOpen2 && (
          <ul className="dropdown-menu1">
            <li onClick={() => handleLanguageChange('en')}>
              <img src={englishFlag} alt="English" className="flag-icon" /> {t('english')}
            </li>
            <li onClick={() => handleLanguageChange('bs')}>
              <img src={bosnianFlag} alt="Bosanski" className="flag-icon" /> {t('bosnian')}
            </li>
            <li onClick={() => handleLanguageChange('tr')}>
              <img src={turkishFlag} alt="Türkçe" className="flag-icon" /> {t('turkish')}
            </li>
          </ul>
        )}
      </div>
    </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
