import React from 'react';
import './Onama.css'; // Ensure this CSS file is updated with the new styles
import amedic from './images/Amedic2.jpg'
import { useTranslation } from 'react-i18next';

const Onama = () => {
    const { t } = useTranslation();

    return (
        <div className="about-us-container">
            <h1 className='mainTitle'>{t('aboutUs_title')}</h1>
            <div className="content-container">
                <div className="text-container">
                    <p id='firstp'>{t('aboutUs_paragraph1')}</p>
                    <p>{t('aboutUs_paragraph2')}</p>
                    <p>{t('aboutUs_paragraph3')}</p>
                </div>
                <div className="image-container">
                    {/* Ensure you have imported `amedic` image or adjusted the path accordingly */}
                    <img src={amedic} alt="Amediclab" />
                </div>
            </div>
            <div className="full-length-text">
                <p>{t('aboutUs_fullLengthText1')}</p>
                <p>{t('aboutUs_fullLengthText2')}</p>
                <p>{t('aboutUs_fullLengthText3')}</p>
            </div>
        </div>
    );
};

export default Onama;
