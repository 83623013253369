import logo from '../src/components/amedic.png';
import Navbar from '../src/components/navbar'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './i18n';
import './App.css';
import Pocetna from './Pocetna';
import Onama from './Onama';
import ProductList from './ProductList';
import Footer from './Footer';
import News from './News';

function App() {
  return (
    <Router>
    <div className="page-container"> {/* Flex container */}
      <Navbar />
      <div className="content-wrap"> {/* Content wrapper */}
        <Routes>
          <Route path="/" element={<Pocetna />} />
          <Route path="/o-nama" element={<Onama />} />
          <Route path="/prodajni-programi" element={<ProductList />} />
          <Route path="/novosti" element={<News />} />
        </Routes>
      </div>
      <Footer />
    </div>
  </Router>
  );
}

export default App;
