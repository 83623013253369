import React, { useState, useEffect } from 'react';
// import { IgApiClient } from 'instagram-private-api';
import image1 from "./images/Amedic.jpg";
import image2 from "./images/Amedic3.jpg";
import "./Pocetna.css";
import Onama from './Onama';
const images = [
    image2,
  image1, 	
];
const preloadImages = () => {
  [image1, image2].forEach(src => {
    const img = new Image();
    img.src = src;
  });
};
function Pocetna() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [previousImageIndex, setPreviousImageIndex] = useState(null);
    const [posts, setPosts] = useState(() => {
        // Attempt to read posts from sessionStorage
        const savedPosts = sessionStorage.getItem('posts');
        return savedPosts ? JSON.parse(savedPosts) : [];
      });
  
      useEffect(() => {
        const intervalId = setInterval(() => {
          setPreviousImageIndex(currentImageIndex);
          setCurrentImageIndex(currentIndex => (currentIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds
    
        // Only fetch posts if they're not already saved in sessionStorage
        // if (posts.length === 0) {
        //   const fetchPosts = async () => {
        //     const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;
        //     const url = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=${accessToken}`;
    
        //     try {
        //       const response = await fetch(url);
        //       const data = await response.json();
        //       setPosts(data.data); // Assuming the API returns the posts in `data.data`
        //       sessionStorage.setItem('posts', JSON.stringify(data.data)); // Save fetched posts to sessionStorage
        //     } catch (error) {
        //       console.error("Error fetching data: ", error);
        //     }
        //   };
        //   fetchPosts();
        // }
        preloadImages();
        return () => clearInterval(intervalId); // Clean up interval on component unmount
      }, [currentImageIndex, posts.length]);
  
    return (
      <>
        <div className="slideshow-container">
          {images.map((image, index) => (
            <div
              key={index}
              className={`image-slide ${index === currentImageIndex ? "active" : index === previousImageIndex ? "outgoing" : ""}`}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
        < Onama />
        {/* <div className="content">
          <h1 style={{ textAlign: 'center' }}>Novosti</h1> */}
          {/* Additional content below the Novosti title will go here */}
          {/* {posts.map(post => (
          <div key={post.id} className='card'>
            <img src={post.media_url} alt={post.caption} className="card-img" />
            <div className='card-caption'>{post.caption}</div>
          </div>
        ))} */}
        {/* </div> */}
      </>
    );
  }

export default Pocetna;
