import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {FaInstagram, FaFacebookF } from 'react-icons/fa';
import './News.css'; // Assuming you will create this CSS file

const News = () => {
    
    const { t } = useTranslation();
    const [news, setNews] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://app-delegate-web.azurewebsites.net/api/post/getAmedicLab');
                setNews(response.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);

    // Function to format date and time
    const formatDateTime = (dateString) => {
        return moment(dateString).format('DD MM YYYY, HH:mm');
    };

    return (
        <div>
            <h1 className='mainTitle'>{t('news_title')}</h1>
        <Container>
    <Row className="justify-content-center">
        {news.map((item, index) => (
            <Col key={index} xs={12} sm={6} lg={4} className="mb-4 d-flex align-items-stretch">
               <Card className="news-card">
                            {item.mediaType === "IMAGE" ? (
                                <Card.Img variant="top" src={item.mediaUrl} className="news-image" />
                            ) : (
                                <video controls className="news-video">
                                    <source src={item.mediaUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                            <Card.Body className="news-text">
                                <Card.Text>{item.caption}</Card.Text>
                                <Card.Text>
                                    <small className="text-muted">{t('date_posted')} {formatDateTime(item.timestamp)}</small>
                                </Card.Text>
                            </Card.Body>
    <Card.Footer style={{ backgroundColor: '#6AC9CB', color: '#FFFFFF', display: 'flex', alignItems: 'space-between', justifyContent: 'space-between' }}>
      <FaInstagram style={{ marginRight: '8px', fontSize: '1.5rem' }} />
      <a href={item.permalink} style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
      {t('show_on_ig')}
      </a>
      <div></div>
    </Card.Footer>
                        </Card>
            </Col>
        ))}
    </Row>
</Container>
        </div>
       
    );
};

export default News;
