import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProductList.css'
const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '20px',
    },
    card: {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
      transition: '0.3s',
      borderRadius: '5px',
      margin: '10px',
      backgroundColor: '#ffffff',
      color: '#727270',
      width: '80%', // Adjust this value as needed
      maxWidth: '600px', // Prevents the card from becoming too wide on larger screens
      boxSizing: 'border-box', // Ensures padding and border are included in the element's total width and height
    },
    cardHeader: {
      fontWeight: 'bold',
      fontSize: '18px',
      color: '#fff',
      backgroundColor: '#6ac9cb',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      width: '100%',
      padding: '20px',
      margin: '0', // Negative margins to extend the header background to the card edges
      boxSizing: 'border-box', // This ensures the padding is included within the element's width
      textAlign: 'center',
    },
    list: {
      listStyleType: 'none',
      paddingLeft: '0',
      padding: '20px',
      paddingTop: '0', // Remove top padding to align the first item with the card's edge
      boxSizing: 'border-box', // Apply to all elements to maintain consistency
    },
    listItem: {
      padding: '1rem 0 0.5rem',
      borderBottom: '2px solid #6ac9cb',
      boxSizing: 'border-box', // Consistent box-sizing for list items
      fontWeight: 'bold',
    },
    mainTitle:{
        fontSize: '32px',
        textAlign: 'center',
        paddingLeft: '1rem',
        backgroundColor: '#6ac9cb', // Background color for the title
        color: '#fff', // Text color
        width: '100%', // Ensure it spans the full container width
        padding: '20px 0', // Add some padding for better spacing
        margin: '0 0 20px 0', // Adjust margin for spacing, especially below the title
        boxSizing: 'border-box', // Include padding and border in the element's total dimensions
    },
  };
  
  
const ProductList = () => {
  const { t } = useTranslation();

  const categories = [
    {
      title: t('salesPrograms_professionalHygiene'),
      items: [
        t('salesPrograms_disinfectants'),
        t('salesPrograms_wetWipes'),
        t('salesPrograms_disposableProtectiveGown'),
      ],
    },
    {
      title: t('salesPrograms_medicalDevices'),
      items: [
        t('salesPrograms_sterileCompresses'),
        t('salesPrograms_nonSterileCompresses'),
        t('salesPrograms_incontinenceProducts'),
        t('salesPrograms_nonSterileExaminationGloves'),
        t('salesPrograms_nitrileGlovesWithoutPowder'),
        t('salesPrograms_latexGlovesWithPowder'),
        t('salesPrograms_latexGlovesWithoutPowder'),
        t('salesPrograms_sterileSurgicalGloves'),
        t('salesPrograms_sterileSurgicalGlovesWithPowder'),
        t('salesPrograms_sterileSurgicalGlovesWithoutPowder'),
        t('salesPrograms_syringesAndNeedles'),
        t('salesPrograms_skinPlastersNonSterile'),
        t('salesPrograms_bandages'),
        t('salesPrograms_masks'),
        t('salesPrograms_disposableCaps'),
        t('salesPrograms_disposableShoeCovers'),
        t('salesPrograms_nasalCannulae'),
        t('salesPrograms_infusionSystems'),
        t('salesPrograms_surgicalDisposableGowns'),
      ],
    },
    {
      title: t('salesPrograms_paperConfection'),
      items: [
        t('salesPrograms_towels'),
        t('salesPrograms_paperRollsForBed'),
      ],
    },
    {
      title: t('salesPrograms_others'),
      items: [
        t('salesPrograms_disposableBedCovers'),
        t('salesPrograms_wasteBinsForInfectiousWaste'),
      ],
    },
  ];
  return (
    <div style={styles.container}>
      <h1 className="mainTitle">{t('salesPrograms_title')}</h1>
      {categories.map((category, index) => (
        <div key={index} style={styles.card}>
          <h3 style={styles.cardHeader}>{category.title}</h3>
          <ul style={styles.list}>
            {category.items.map((item, itemIndex) => (
              <li key={itemIndex} style={styles.listItem}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ProductList;
