import React from 'react';
import ReactDOM from 'react-dom';
import './ModalComponent.css';
const modalRoot = document.getElementById('modal-root') || document.body; // Fallback to document.body if 'modal-root' is not available.

const ModalComponent = ({ isOpen, onRequestClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onRequestClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {children}
        <button className="modal-close" onClick={onRequestClose}>Close</button>
      </div>
    </div>,
    modalRoot,
  );
};

export default ModalComponent;
