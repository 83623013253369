import React, {useState} from 'react';
import ModalComponent from './components/ModalComponent';
import { Document, Page } from '@react-pdf/renderer';
import Modal from 'react-modal';
// If you're using CSS Modules
import styles from './Footer.module.css'; // Adjust the path according to your file structure
import logo3 from './images/logo3.png'
import PolitikaKvaliteta from './politika.pdf'
import ISO from './ISO.pdf'
import { useTranslation } from 'react-i18next';
import { FaHome, FaPhone, FaMobileAlt, FaEnvelope, FaInstagram, FaFacebookF } from 'react-icons/fa';

Modal.setAppElement('#root');
const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000, // Increase this value to a number high enough to be on top of other elements
  },
  content: {
    zIndex: 1000, // Ensure the content also has a high z-index
  },
};
const Footer = () => {
  const handleContextMenu = (e) => {
    console.log("test");
    e.preventDefault();
  };
  // const disableContextMenu = (event) => event.preventDefault();
  // document.addEventListener('contextmenu', event => event.preventDefault());
  const { t } = useTranslation();
  const iconStyle = { marginRight: '10px', color: 'white', fontSize: '1.5em' };
  const textStyle = { color: 'white', display: 'flex', alignItems: 'center' }; // Adjusted for vertical centering
  const itemStyle = { marginBottom: '15px', display: 'flex', alignItems: 'center' }; // Use flexbox for alignment
  const socialIconStyle = { color: 'white', fontSize: '2em', marginLeft: '1rem', marginBottom:'0.1rem' }; // Style for social icons
  const buttonStyle = {
    color: 'white',
    backgroundColor: 'black',
    fontWeight: 'bold',
    margin: '2px 10px 10px 10px', // Top margin is 2px, right and left margins are 10px, and bottom margin is 10px
    padding: '8px 5px 5px 5px', // Top padding is 2px, right and left paddings are 20px, and bottom padding is 10px
    border: 'none',
    cursor: 'pointer',
    fontSize: '1.1rem'
  }; 
  const overlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000, // Ensure it's above the iframe content
  };
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const iframe = document.getElementById('fraDisabled');

  const openModalWithPdf = (url) => {
    setPdfFile(url);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Create the email body with actual line breaks
    const emailBody = `Email: ${email} \n Ime i Prezime: ${name} \n Poruka: ${message}`;
    // Encode the email body to ensure it's compatible with mailto links
    const encodedEmailBody = encodeURIComponent(emailBody);

    // Construct the mailto link with the encoded email body
    const mailtoLink = `mailto:info@amediclab.com?subject=Poruka sa web sajta&body=${encodedEmailBody}`;

    // Open the default mail client with the mailto link
    window.location.href = mailtoLink;
  };
  return (
    <footer className={styles['footer-distributed']}>
      <div className={styles['footer-left']}>
        <img src={logo3} className={styles['logo3']}/>
        <p className={styles['footer-links']}>
          <a href="/">{t('footer_home')}</a>
          &nbsp;&nbsp;·&nbsp;&nbsp;
          <a href="/o-nama">{t('footer_aboutUs')}</a>
          &nbsp;&nbsp;·&nbsp;&nbsp;
          <a href="/prodajni-programi">{t('footer_salesPrograms')}</a>
        </p>

        {/* Icons and contact info */}
        <div style={{padding: '20px', textAlign: 'center', backgroundColor: '#1F2022'}}>
          <div style={itemStyle}>
            <FaHome style={iconStyle} />
            <span style={textStyle}>{t('footer_address')}</span>
          </div>
          <div style={itemStyle}>
            <FaPhone style={iconStyle} />
            <span style={textStyle}>{t('footer_phone')}</span>
          </div>
          <div style={itemStyle}>
            <FaMobileAlt style={iconStyle} />
            <span style={textStyle}>{t('footer_mobile')}</span>
          </div>
          <div style={itemStyle}>
            <FaEnvelope style={iconStyle} />
            <span style={textStyle}>{t('footer_email')}</span>
          </div>
        </div>

        {/* Social icons */}
        <div className={styles['icons']}>
          <a href="https://www.instagram.com/amediclab.sarajevo/" target="_blank" rel="noopener noreferrer">
            <FaInstagram style={socialIconStyle} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61556334873308" target="_blank" rel="noopener noreferrer">
            <FaFacebookF style={socialIconStyle} />
          </a>
        </div>

        {/* PDF buttons and copyright notice */}
        <div className={styles['footer-links']}>
          <button style={buttonStyle} onClick={() => openModalWithPdf(PolitikaKvaliteta)}>{t('footer_qualityPolicy')}</button>
          <button style={buttonStyle} onClick={() => openModalWithPdf(ISO)}>{t('footer_isoCertificate')}</button>
          <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="PDF"
        style={customStyles}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* Transparent div to capture right-clicks */}
        <div
          style={overlayStyles}
          onContextMenu={handleContextMenu}
        ></div>
        <iframe
          id="fraDisabled"
          src={pdfFile + "#toolbar=0"}
          style={{ width: '100%', height: '100%', overflow: 'auto', position: 'absolute', top: 0, left: 0 }}
          allow="autoplay"
          allowFullScreen
        ></iframe>
      </div>
        <button onClick={closeModal}>Close</button>
      </Modal>
        </div>
        <p className={styles['footer-company-name']}>{t('footer_copyright')}</p>
      </div>

      {/* Contact form */}
      <div className={styles['footer-right']}>
        <p id={styles['contact']}>{t('footer_contactUs')}</p>
        {/* Form submission logic should be handled accordingly */}
        <form onSubmit={handleSubmit}>
          <input type="text" name="email" placeholder={t('footer_emailPlaceholder')} value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="text" name="name" placeholder={t('footer_namePlaceholder')} value={name} onChange={(e) => setName(e.target.value)} />
          <textarea name="message" placeholder={t('footer_messagePlaceholder')} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
          <button type="submit">{t('footer_send')}</button>
        </form>
      </div>
    </footer>
  );
};

export default Footer;
